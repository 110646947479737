/* Starfield main container */
.starfield {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 300%;
  overflow: hidden;
  z-index: -1; /* Ensure behind the login box */
  background: black; /* Black background to simulate space */
}

/* Individual stars */
.stars {
  width: 1px;
  height: 1px;
  border-radius: 50%;
  position: absolute;
  background-color: white; /* White stars */
  animation: travel 5s linear infinite; /* Linear animation for continuous travel */
}

/* Keyframes for the travelling stars effect */
@keyframes travel {
  0% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(5); /* Make stars larger as they move outwards */
    opacity: 0;
  }
}

/* Default dark/light theme star colors */
.starfield.light .stars {
  background-color: gray;
}

.starfield.dark .stars {
  background-color: white;
}

/* Specific theme star colors based on palette */
.starfield.sissy .stars {
  background-color: #ff4081; /* Pink starfield for sissy theme */
}

.starfield.pastel .stars {
  background-color: #8e99f3; /* Lavender blue stars for pastel theme */
}

.starfield.seaGreen .stars {
  background-color: #80cbc4; /* Light teal stars for seaGreen theme */
}

.starfield.autumn .stars {
  background-color: #ffa726; /* Orange stars for autumn theme */
}

.starfield.lavender .stars {
  background-color: #b39ddb; /* Light purple stars for lavender theme */
}

.starfield.mint .stars {
  background-color: #80cbc4; /* Mint green stars for mint theme */
}

.starfield.skyBlue .stars {
  background-color: #4fc3f7; /* Sky blue stars for skyBlue theme */
}

.starfield.sunset .stars {
  background-color: #ffb74d; /* Sunset orange stars */
}

.starfield.olive .stars {
  background-color: #afb42b; /* Olive green stars */
}

/* Global starfield styles */
body.dark-theme::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #121212;
  z-index: -2;
}

.dark-theme .starfield {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
  pointer-events: none;
}

.dark-theme .stars {
  width: 1px;
  height: 1px;
  background: white;
  position: absolute;
  border-radius: 50%;
  animation: travel 5s linear infinite;
}

@keyframes travel {
  0% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(5);
    opacity: 0;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes pulseScale {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(0.8);
  }
}

.spinning-logo {
  animation: spin 4s linear infinite, pulseScale 3s ease-in-out infinite;
}