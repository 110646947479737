/* Base layout */
.mobile-calendar {
  height: 100% !important;
  width: 100% !important;
  overflow: hidden !important;
}

/* Calendar container */
.mobile-calendar .rbc-calendar {
  background-color: #ffffff;
  height: 100% !important;
  width: 100% !important;
  overflow: hidden !important;
}

/* Time View Layout */
.mobile-calendar .rbc-time-view {
  display: flex !important;
  flex-direction: column !important;
  border: none !important;
  height: 100% !important;
}

/* Header section */
.mobile-calendar .rbc-time-header {
  width: 100% !important;
  min-height: 120px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

/* Time gutter (first column) */
.mobile-calendar .rbc-time-gutter,
.mobile-calendar .rbc-time-header-gutter {
  flex: 0 0 75px !important;
  width: 75px !important;
}

/* Column alignment */
.mobile-calendar .rbc-time-header-content {
  flex: 1 1 0 !important;
  min-width: 0 !important;
}

.mobile-calendar .rbc-time-content {
  display: flex !important;
  flex: 1 1 auto !important;
  align-items: stretch !important;
  height: calc(100% - 120px) !important;
}

.mobile-calendar .rbc-time-column {
  flex: 1 1 0 !important;
  min-width: 0 !important;
}

/* Header styling */
.mobile-calendar .rbc-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 50px !important;
  padding: 6px 2px !important;
  border: none !important;
}

.rbc-header-day {
  font-size: 0.875rem;
  text-transform: uppercase;
  margin-bottom: 2px;
  display: block;
  color: rgba(0, 0, 0, 0.6);
}

.rbc-header-date {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.2;
  display: block;
}

/* Time slots */
.mobile-calendar .rbc-timeslot-group {
  min-height: 50px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mobile-calendar .rbc-time-slot {
  min-height: 25px;
  transition: background-color 0.2s ease;
}

/* Time slot labels */
.mobile-calendar .rbc-time-gutter .rbc-timeslot-group {
  font-size: 0.75rem;
}

.mobile-calendar .rbc-time-gutter .rbc-label {
  padding: 0 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Events styling */
.mobile-calendar .rbc-event {
  padding: 1px 2px !important;
  font-size: 0.35rem !important;
  border-radius: 2px;
  border: none !important;
  height: 20px !important;
  min-height: 20px !important;
  line-height: 20px !important;
  overflow: hidden;
}

.mobile-calendar .rbc-event-content {
  display: flex !important;
  align-items: center !important;
  height: 100% !important;
  gap: 2px;
}

.mobile-calendar .rbc-event-content > div {
  display: flex !important;
  align-items: center !important;
  width: 100%;
  height: 100%;
}

.mobile-calendar .rbc-month-view .rbc-event {
  height: 20px !important;
  min-height: 20px !important;
  line-height: 20px !important;
  padding: 1px 2px !important;
}

.mobile-calendar .rbc-month-view .rbc-event-content {
  height: 20px !important;
  line-height: 20px !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* All-day events area */
.mobile-calendar .rbc-time-header-content .rbc-allday-cell {
  height: auto !important;
  max-height: none !important;
  min-height: 60px;
  overflow-y: auto !important;
}

.rbc-allday-cell .rbc-row-content {
  position: relative;
  z-index: 1;
}

.rbc-event.rbc-selected {
  display: flex !important;
  align-items: center !important;
}

.rbc-allday-cell .rbc-event.rbc-event-allday {
  height: 20px !important;
  position: relative;
}

/* Light mode specific styles */
.mobile-calendar .rbc-today {
  background-color: rgba(0, 0, 0, 0.04) !important;
}

/* Dark mode styling */
[data-theme='dark'] .mobile-calendar .rbc-calendar {
  background-color: #1e1e1e !important;
  color: rgba(255, 255, 255, 0.87);
}

[data-theme='dark'] .mobile-calendar .rbc-time-view,
[data-theme='dark'] .mobile-calendar .rbc-time-content,
[data-theme='dark'] .mobile-calendar .rbc-time-column {
  background-color: #262626 !important;
}

[data-theme='dark'] .mobile-calendar .rbc-time-header {
  background-color: #262626 !important;
  border: none !important;
}

[data-theme='dark'] .mobile-calendar .rbc-time-header-content {
  border-left: none !important;
}

[data-theme='dark'] .mobile-calendar .rbc-header {
  border: none !important;
  background-color: #262626 !important;
}

[data-theme='dark'] .mobile-calendar .rbc-header-day {
  color: rgba(255, 255, 255, 0.6);
}

[data-theme='dark'] .mobile-calendar .rbc-header-date {
  color: rgba(255, 255, 255, 0.87);
}

/* Dark mode grid lines */
[data-theme='dark'] .mobile-calendar .rbc-timeslot-group,
[data-theme='dark'] .mobile-calendar .rbc-time-content,
[data-theme='dark'] .mobile-calendar .rbc-time-gutter,
[data-theme='dark'] .mobile-calendar .rbc-day-slot,
[data-theme='dark'] .mobile-calendar .rbc-time-view,
[data-theme='dark'] .mobile-calendar .rbc-month-view,
[data-theme='dark'] .mobile-calendar .rbc-month-row,
[data-theme='dark'] .mobile-calendar .rbc-row-bg {
  border-color: rgba(255, 255, 255, 0.12) !important;
}

[data-theme='dark'] .mobile-calendar .rbc-day-bg + .rbc-day-bg,
[data-theme='dark'] .mobile-calendar .rbc-month-row + .rbc-month-row {
  border-color: rgba(255, 255, 255, 0.12) !important;
}

[data-theme='dark'] .mobile-calendar .rbc-timeslot-group {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12) !important;
  background-color: #262626 !important;
}

[data-theme='dark'] .mobile-calendar .rbc-today {
  background-color: rgba(255, 255, 255, 0.08) !important;
}

/* Drag and drop styling */
.mobile-calendar .time-slot-wrapper {
  height: 100%;
  transition: background-color 0.2s;
}

.mobile-calendar .time-slot-wrapper.drop-target {
  background-color: rgba(0, 120, 255, 0.1);
}

[data-theme='dark'] .mobile-calendar .time-slot-wrapper.drop-target {
  background-color: rgba(30, 144, 255, 0.2);
}

/* Scrollbar styling */
.mobile-calendar .rbc-time-content {
  scroll-behavior: smooth;
}

.mobile-calendar .rbc-time-content::-webkit-scrollbar {
  width: 8px;
}

.mobile-calendar .rbc-time-content::-webkit-scrollbar-track {
  background: transparent;
}

.mobile-calendar .rbc-time-content::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

[data-theme='dark'] .mobile-calendar .rbc-time-content::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.2);
}

/* Mobile responsiveness */
@media (max-width: 600px) {
  .mobile-calendar .rbc-toolbar {
    font-size: 12px;
  }

  .mobile-calendar .rbc-toolbar button {
    padding: 3px 5px;
  }

  .mobile-calendar .rbc-time-slot {
    min-height: 20px;
  }

  .mobile-calendar .rbc-time-gutter,
  .mobile-calendar .rbc-time-header-gutter {
    width: 50px !important;
    flex: 0 0 50px !important;
  }
}

/* Toolbar styling */
.mobile-calendar .rbc-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding: 0 1rem;
}

/* Create event popup */
.create-event-popup {
  position: absolute;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  padding: 8px;
  z-index: 1000;
}

[data-theme='dark'] .create-event-popup {
  background: #2d2d2d;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}

/* Desktop layout */
@media (min-width: 1024px) {
  .calendar-desktop-layout {
    display: grid;
    grid-template-columns: 300px 1fr;
    grid-template-rows: auto 1fr;
    gap: 16px;
    height: 100%;
  }

  .calendar-desktop-layout .mini-calendar {
    grid-column: 1;
    grid-row: 1;
  }

  .calendar-desktop-layout .unallocated-tasks {
    grid-column: 1;
    grid-row: 2;
    overflow-y: auto;
  }

  .calendar-desktop-layout .week-schedule {
    grid-column: 2;
    grid-row: 1 / span 2;
  }
}

/* Month view specific styles */
.mobile-calendar .rbc-month-view {
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
}

[data-theme='dark'] .mobile-calendar .rbc-month-view {
  border: 1px solid rgba(255, 255, 255, 0.12) !important;
  background-color: #262626 !important;
}

/* Off-range days in month view */
.mobile-calendar .rbc-off-range-bg {
  background-color: rgba(0, 0, 0, 0.04);
}

[data-theme='dark'] .mobile-calendar .rbc-off-range-bg {
  background-color: rgba(255, 255, 255, 0.04);
}

/* Time column text colors */
[data-theme='dark'] .mobile-calendar .rbc-time-gutter .rbc-label {
  color: rgba(255, 255, 255, 0.87);
}

/* Event content text color */
[data-theme='dark'] .mobile-calendar .rbc-event-content {
  color: rgba(255, 255, 255, 0.87);
}

/* Ensure all text is readable in dark mode */
[data-theme='dark'] .mobile-calendar {
  color: rgba(255, 255, 255, 0.87);
}

/* Event content layout fixes */
.mobile-calendar .rbc-event-content {
  display: flex !important;
  align-items: center !important;
  height: 100% !important;
}

.mobile-calendar .rbc-event-content > div {
  display: flex !important;
  align-items: center !important;
  gap: 2px !important;
  width: 100%;
  height: 100%;
}

.mobile-calendar .rbc-event-content svg {
  width: 16px !important;
  height: 16px !important;
  display: block !important;
}

.mobile-calendar .rbc-event {
  min-height: 20px !important;
  height: 20px !important;
}